import React, { useState } from 'react';
import './PayloadCoroaSemFim.css';
import Header from '../Header/Header';
import api from '../../axios/config';
import Loading from '../Loading/Loading';
import engSemFim from '../../assets/eng_coroa_semfim.jpg';
import ResponseCoroaSemFim from '../ResponseCoroaSemFim/ResponseCoroaSemFim';
import Input from '../Input/Input';

const PayloadCoroaSemFim = () => {
  const tokenAuthenticated = sessionStorage.getItem('token');
  const [numberCogs, setNumberCogs] = useState(4);
  const [pap, setPap] = useState('');
  const [step, setStep] = useState('');
  const [numberEntries, setNumberEntries] = useState('');
  const [mostraModal, setMostraModal] = useState(false);
  const [numberToothGrinding, setNumberToothGrinding] = useState('');
  const [numberToothCrown, setNumberToothCrown] = useState('');
  const [diameter, setDiameter] = useState('');
  const [diameterBottom, setDiameterBottom] = useState('');
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [gearType, setGearType] = useState('Direita');

  const postApiCalculatedRenania = async () => {
    const payload = {
      pap: Number(pap),
      step: Number(step),
      entries: Number(numberEntries),
      num_engines: Number(numberCogs),
      teeth: Number(numberToothGrinding),
      crown_teeth: Number(numberToothCrown),
      diameter: Number(diameter),
      bottom_diameter: Number(diameterBottom),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
      gear_type: gearType,
    };
    setLoading(true);
    setValuesAPI([]);
    await api
      .post('engine/calc_endless_crown', payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error('ERRO ->', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 className="titulo">
        Cálculo: <strong>COROA E SEM FIM NA FRESADORA UNIVERSAL</strong>
      </h1>
      <div className="max-w-3xl mx-auto p-6 bg-white shadow-xl mt-3 rounded-lg">
        <div className="flex justify-center">
          <img
            src={engSemFim}
            alt="Engrenagem Coroa Sem Fim"
            className="w-96 h-80"
          />
        </div>
        <div className="mb-4 p-1 bg-gray-100 rounded-md shadow text-center">
          <label className="text-lg font-medium text-gray-700">
            Número de engrenagens:
          </label>
          <div className="flex justify-center gap-4 mt-2">
            {[2, 4].map((value) => (
              <label
                key={value}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  value={value}
                  checked={numberCogs === value}
                  onChange={(event) =>
                    setNumberCogs(parseInt(event.target.value))
                  }
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all ${
                    numberCogs === value
                      ? 'border-blue-600 bg-blue-600'
                      : 'border-gray-400 bg-white'
                  }`}
                >
                  {numberCogs === value && (
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  )}
                </div>
                <span className="text-lg font-bold text-gray-800">{value}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="mt-8 mb-2">
          <strong>FRESADORA:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
          {[
            {
              label: 'Numero de dentes do divisor(Z):*',
              value: numberToothGrinding,
              setter: setNumberToothGrinding,
              placeholder: '(Z) Coroa Divisor',
            },
            {
              label: 'Passo do Fuso (Fres.):*',
              value: step,
              setter: setStep,
              placeholder: 'Passo Fuso Fresadora',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>
        <div className="mt-8 mb-2">
          <strong>SEM FIM:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Sentido de Hélice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Direita">Direita</option>
              <option value="Esquerda">Esquerda</option>
            </select>
          </div>
          {[
            {
              label: 'PAP (Passo aparente):*',
              value: pap,
              setter: setPap,
              placeholder: 'Passo do sem fim',
            },
            {
              label: 'N (Número de entradas):*',
              value: numberEntries,
              setter: setNumberEntries,
              placeholder: 'Entradas do sem fim',
            },
            {
              label: 'DE (Diâmetro externo sem fim):*',
              value: diameter,
              setter: setDiameter,
              placeholder: 'Diâmetro externo sem fim',
            },
            {
              label: 'DI (Diâmetro interno sem fim):*',
              value: diameterBottom,
              setter: setDiameterBottom,
              placeholder: 'Diâmetro interno sem fim',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>
        <div className="mt-8 mb-2">
          <strong>COROA:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
          {[
            {
              label: 'Número de dentes da coroa a fresar:*',
              value: numberToothCrown,
              setter: setNumberToothCrown,
              placeholder: '(Z) Coroa a fresar',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>
        <div className="mt-8 mb-2">
          <strong>CONFIGURAÇÕES:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
          {[
            {
              label: 'Número de Dentes mínimos:*',
              value: minTeeth,
              setter: setMinTeeth,
              placeholder: 'Número de Dentes mínimos',
            },
            {
              label: 'Número de Dentes máximos:*',
              value: maxTeeth,
              setter: setMaxTeeth,
              placeholder: 'Número de Dentes máximos',
            },
            {
              label: 'Número de exemplos:*',
              value: numberSample,
              setter: setNumberSample,
              placeholder: 'Número de exemplos',
            },
            {
              label: 'Precisão:*',
              value: precision,
              setter: setPrecision,
              placeholder: 'Precisão',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>

        <div className="my-6">
          <button
            className={`w-full py-3 rounded-lg font-semibold text-white transition ${
              pap === '' ||
              step === '' ||
              numberToothGrinding === '' ||
              numberEntries === '' ||
              diameter === '' ||
              diameterBottom === '' ||
              numberToothCrown === '' ||
              minTeeth === '' ||
              maxTeeth === '' ||
              numberSample === '' ||
              precision === ''
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            onClick={() => {
              postApiCalculatedRenania();
              setMostraModal(true);
            }}
            disabled={
              pap === '' ||
              step === '' ||
              numberToothGrinding === '' ||
              numberEntries === '' ||
              diameter === '' ||
              diameterBottom === '' ||
              numberToothCrown === '' ||
              minTeeth === '' ||
              maxTeeth === '' ||
              numberSample === '' ||
              precision === ''
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseCoroaSemFim
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              pap={pap}
              step={step}
              diameter={diameter}
              numberToothGrinding={numberToothGrinding}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadCoroaSemFim;
