import React from 'react';

const Input = ({ label, value, onChange, placeholder, disabled }) => {
  return (
    <>
      <div>
        <label className="block text-gray-700 text-sm font-bold">{label}</label>
        <input
          type="number"
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          min={0}
          onWheel={(e) => e.target.blur()}
          className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
        />
      </div>
    </>
  );
};

export default Input;
