import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PayloadDaD from "./components/PayloadDaD/PayloadDaD";
import PayloadRenaniaAdap from "./components/PayloadRenaniaAdap/PayloadRenaniaAdap";
import Login from "./components/Login/Login";
import PayloadRenaniaUniversal from "./components/PayloadRenaniaUniversal/PayloadRenaniaUniversal";
import PayloadCoroaSemFim from "./components/PayloadCoroaSemFim/PayloadCoroaSemFim";
import TableUsers from "./components/TableUsers/TableUsers";
import LoginADM from "./components/LoginADM/LoginADM";
import AuthGuard from "./guard/Auth";
import User from "./components/User/User";
import PayloadEngConica from "./components/PayloadEngConica/PayloadEngConica";
import PayloadEngHipoide from "./components/PayloadEngHipoide/PayloadEngHipoide";
import Dashboard from "./components/Dashboard/Dashboard";
import HomePage from "./components/HomePage/HomePage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/adm/login' element={<LoginADM />} />
          <Route
            path='/fresuniversal'
            element={
              <AuthGuard>
                <Dashboard />
              </AuthGuard>
            }
          />
          <Route
            path='/denteadente'
            element={
              <AuthGuard>
                <PayloadDaD />
              </AuthGuard>
            }
          />
          <Route
            path='/renaniaadap'
            element={
              <AuthGuard>
                <PayloadRenaniaAdap />
              </AuthGuard>
            }
          />
          <Route
            path='/renaniauniversal'
            element={
              <AuthGuard>
                <PayloadRenaniaUniversal />
              </AuthGuard>
            }
          />
          <Route
            path='/coroasemfim'
            element={
              <AuthGuard>
                <PayloadCoroaSemFim />
              </AuthGuard>
            }
          />
          <Route
            path='/engconica'
            element={
              <AuthGuard>
                <PayloadEngConica />
              </AuthGuard>
            }
          />
          <Route
            path='/enghipoide'
            element={
              <AuthGuard>
                <PayloadEngHipoide />
              </AuthGuard>
            }
          />
          <Route
            path='/user'
            element={
              <AuthGuard>
                <User />
              </AuthGuard>
            }
          />

          <Route
            path='/adm/users'
            element={
              <AuthGuard>
                <TableUsers />
              </AuthGuard>
            }
          />

          <Route path='/login' element={<Login />} />
          <Route path='/adm' element={<LoginADM />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
