import React from 'react';
import Header from '../Header/Header';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/coroasemfim');
  };

  const handleClickDad = () => {
    navigate('/denteadente');
  };

  const handleClickConica = () => {
    navigate('/engconica');
  };

  const handleClickHipoide = () => {
    navigate('/enghipoide');
  };

  return (
    <>
      <Header />
      <div className="container_information">
        <div className="button_for_click" onClick={handleClickDad}>
          ENGRENAGEM PARALELA RETA OU HELICOIDAL
        </div>
        <div className="button_for_click" onClick={handleClickConica}>
          ENGRENAGEM CONICA RETA
        </div>
        <div className="button_for_click" onClick={handleClickHipoide}>
          ENGRENAGEM CONICA ESPIRAL
        </div>
        <div className="button_for_click" onClick={handleClick}>
          COROA E SEM-FIM{' '}
        </div>
        {/* <div className="button_for_click">ENGRENAGEM DE CORRENTE</div> */}
        {/* <div className="button_for_click">POLIA DENTADA</div> */}
      </div>
    </>
  );
};

export default Dashboard;
