import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import InputText from '../InputText/InputText';
import Loading from '../Loading/Loading';
import api from '../../axios/config';

const User = () => {
  const tokenAuthenticated = sessionStorage.getItem('token');
  const [activeTab, setActiveTab] = useState('dados');
  const [loading, setLoading] = useState(false);
  const [objectUser, setObjectUser] = useState({
    name: '',
    password: '',
    confirmPassword: '',
    phone: '',
    cpf_cnpj: '',
  });

  const getAPIUserMe = async () => {
    setLoading(true);
    try {
      const response = await api.get('user/me', {
        headers: {
          token: tokenAuthenticated,
        },
      });
      const data = response.data;
      setObjectUser({
        name: data.data.name,
        password: '',
        confirmPassword: '',
        phone: data.data.phone,
        cpf_cnpj: data.data.cpf_cnpj,
      });
    } catch (error) {
      console.log('ERRO', error);
    } finally {
      setLoading(false);
    }
  };

  const patchAPIUpdateUser = async () => {
    setLoading(true);
    try {
      const response = await api.patch(
        'user/edit/me',
        {
          name: objectUser.name,
          phone: objectUser.phone,
          cpf_cnpj: objectUser.cpf_cnpj,
          password: objectUser.password,
        },
        {
          headers: {
            token: tokenAuthenticated,
          },
        }
      );
      const data = response.data;

      setObjectUser({
        name: '',
        email: '',
        password: '',
        phone: '',
        cpf_cnpj: '',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPIUserMe();
  }, []);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSaveUserData = (e) => {
    e.preventDefault();
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('As novas senhas não coincidem!');
    } else {
      alert('Senha alterada com sucesso!');
    }
  };
  return (
    <>
      <Header />
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-white-500 to-white-600">
        <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-lg">
          <div className="flex justify-around mb-6">
            <button
              className={`w-full py-2 rounded-t-lg font-bold transition-colors ${
                activeTab === 'dados'
                  ? 'bg-gray-500 text-white'
                  : 'bg-gray-200 text-white-600'
              }`}
              onClick={() => setActiveTab('dados')}
            >
              Dados do Usuário
            </button>
            <button
              className={`w-full py-2 rounded-t-lg font-bold transition-colors ${
                activeTab === 'senha'
                  ? 'bg-gray-500 text-white'
                  : 'bg-gray-200 text-white-600'
              }`}
              onClick={() => setActiveTab('senha')}
            >
              Trocar Senha
            </button>
          </div>
          {activeTab === 'dados' && (
            <div>
              <h2 className="text-2xl font-semibold text-center mb-4 text-black-700">
                Editar minhas informações:
              </h2>
              <form onSubmit={handleSaveUserData}>
                <div className="mb-4">
                  <InputText
                    label={'Nome:'}
                    value={objectUser.name}
                    onChange={(e) =>
                      setObjectUser({ ...objectUser, name: e.target.value })
                    }
                    placeholder={'Digite seu nome'}
                  />
                </div>
                <div className="mb-4">
                  <InputText
                    label={'Telefone:'}
                    value={objectUser.phone}
                    mask="(99) 99999-9999"
                    onChange={(e) =>
                      setObjectUser({ ...objectUser, phone: e.target.value })
                    }
                    placeholder={'Digite seu telefone'}
                  />
                </div>
                <div className="mb-4">
                  <InputText
                    label={'CPF / CNPJ:'}
                    disabled={true}
                    value={objectUser.cpf_cnpj}
                    onChange={(e) =>
                      setObjectUser({ ...objectUser, cpf_cnpj: e.target.value })
                    }
                    placeholder={'Digite seu cpf ou cnpj'}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-gray-500 text-white py-3 rounded-lg hover:bg-gray-600 transition duration-300"
                  onClick={() => patchAPIUpdateUser()}
                >
                  Salvar Alterações
                </button>
              </form>
            </div>
          )}
          {activeTab === 'senha' && (
            <div>
              <h2 className="text-2xl font-semibold text-center mb-4 text-bg(#2563eb)-700">
                Editar minha senha:
              </h2>
              <form onSubmit={handlePasswordChange}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-medium mb-1">
                    Nova Senha
                  </label>
                  <input
                    type="password"
                    className="w-full p-3 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-200"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 font-medium mb-1">
                    Confirme a Nova Senha
                  </label>
                  <input
                    type="password"
                    className="w-full p-3 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-200"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-gray-500 text-white py-3 rounded-lg hover:bg-gray-600 transition duration-300"
                >
                  Alterar Senha
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default User;
