import React, { useState } from 'react';
import Header from '../Header/Header';
import Input from '../Input/Input';
import Loading from '../Loading/Loading';
import api from '../../axios/config';
import ResponseEngConicaAPI from '../ResponseEngConicaAPI/ResponseEngConicaAPI';

const PayloadEngConica = () => {
  const tokenAuthenticated = sessionStorage.getItem('token');
  const [angle, setAngle] = useState(15);
  const [axisAngle, setAxisAngle] = useState(90);
  const [teethz1, setTeethz1] = useState('');
  const [teethz2, setTeethz2] = useState('');
  const [module, setModule] = useState('');
  const [diameterExternal1, setDiameterExternal1] = useState('');
  const [diameterExternal2, setDiameterExternal2] = useState('');
  const [moduleOrdiameter, setModuleOrdiameter] = useState('Módulo');
  const [crownTeeth1, setCrownTeeth1] = useState('');
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostraModal, setMostraModal] = useState(false);

  const postApiCalculatedEngConica = async () => {
    const payload = {
      angle: Number(angle),
      teeth1: Number(teethz1),
      teeth2: Number(teethz2),
      ...(moduleOrdiameter === 'Módulo'
        ? { module: Number(module) }
        : {
            diameter1: Number(diameterExternal1),
            diameter2: Number(diameterExternal2),
          }),

      axis_angle: Number(axisAngle),
      crown_teeth1: Number(crownTeeth1),
      crown_teeth2: Number(crownTeeth1),
    };
    setLoading(true);
    setValuesAPI([]);
    await api
      .post('engine/calc_conical', payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error('ERRO ->', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 className="titulo">
        Cálculo: <strong>ENGRENAGEM CÔNICA RETA NA FRESADORA UNIVERSAL</strong>
      </h1>

      <div className="max-w-3xl mx-auto p-6 bg-white shadow-xl mt-3 rounded-lg">
        <div className="mb-4 p-1 bg-gray-100 rounded-md shadow text-center">
          <label className="text-lg font-medium text-gray-700">
            Ângulo Pressão:
          </label>
          <div className="flex justify-center gap-4 mt-2">
            {[15, 20].map((value) => (
              <label
                key={value}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  value={value}
                  checked={angle === value}
                  onChange={(event) => setAngle(parseInt(event.target.value))}
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all ${
                    angle === value
                      ? 'border-blue-600 bg-blue-600'
                      : 'border-gray-400 bg-white'
                  }`}
                >
                  {angle === value && (
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  )}
                </div>
                <span className="text-lg font-bold text-gray-800">{value}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Módulo / Diametro:*
            </label>
            <select
              value={moduleOrdiameter}
              onChange={(e) => setModuleOrdiameter(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Módulo">Módulo</option>
              <option value="Diametro">Diametro</option>
            </select>
          </div>
          {[
            {
              condition: moduleOrdiameter === 'Módulo',
              label: 'Módulo :*',
              value: module,
              setter: setModule,
              placeholder: 'Módulo',
            },
            {
              condition: moduleOrdiameter === 'Diametro',
              label: 'Diametro Externo Z1:*',
              value: diameterExternal1,
              setter: setDiameterExternal1,
              placeholder: 'Diametro Externo Z1',
            },
            {
              condition: moduleOrdiameter === 'Diametro',
              label: 'Diametro Externo Z2:*',
              value: diameterExternal2,
              setter: setDiameterExternal2,
              placeholder: 'Diametro Externo Z2',
            },
            {
              condition: true,
              label: 'Número de dentes(Cor. Div):*',
              value: crownTeeth1,
              setter: setCrownTeeth1,
              placeholder: '(Z) Coroa Divisor',
            },
            {
              condition: true,
              label: 'Dentes do Z1:*',
              value: teethz1,
              setter: setTeethz1,
              placeholder: 'Z1',
            },
            {
              condition: true,
              label: 'Dentes do Z2:*',
              value: teethz2,
              setter: setTeethz2,
              placeholder: 'Z2',
            },
            {
              condition: true,
              label: 'Ângulo do eixo:*',
              value: axisAngle,
              setter: setAxisAngle,
              placeholder: 'Ângulo do eixo',
            },
          ]
            .filter(({ condition }) => condition)
            .map(({ label, value, setter, placeholder }) => (
              <Input
                label={label}
                value={value}
                placeholder={placeholder}
                onChange={(e) => setter(e.target.value)}
              />
            ))}
        </div>

        <div className="my-6">
          <button
            className={`w-full py-3 rounded-lg font-semibold text-white transition ${
              teethz1 === '' ||
              teethz2 === '' ||
              axisAngle === '' ||
              crownTeeth1 === '' ||
              (moduleOrdiameter === 'Diametro'
                ? diameterExternal1 === '' || diameterExternal2 === ''
                : module === '')
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            onClick={() => {
              postApiCalculatedEngConica();
              setMostraModal(true);
            }}
            disabled={
              teethz1 === '' ||
              teethz2 === '' ||
              axisAngle === '' ||
              crownTeeth1 === '' ||
              (moduleOrdiameter === 'Diametro'
                ? diameterExternal1 === '' || diameterExternal2 === ''
                : module === '')
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseEngConicaAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              angle={angle}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadEngConica;
