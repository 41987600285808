import React from 'react';
import { printPDF } from '../../utils/printer';
import engConica from '../../assets/eng_conica.jpg';

const ResponseEngConicaAPI = ({ valuesAPI, setMostraModal, angle }) => {
  return (
    <>
      <div className="removeFilters_background">
        <div className="background_center_removeFilters">
          <div className="div_all_itens_api">
            <div className="flex flex-row justify-center items-center gap-4">
              <button
                onClick={() => setMostraModal(false)}
                className="block w-40 bg-red-600 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-red-400 transition duration-300"
              >
                VOLTAR
              </button>
              <button
                onClick={printPDF}
                className="block w-40 bg-green-500 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-green-400 transition duration-300"
              >
                IMPRIMIR PDF
              </button>
            </div>
            <div className="print_pdf">
              <div className="flex flex-row justify-center items-center">
                <img
                  src={engConica}
                  alt=""
                  style={{ width: '550px', height: '550px' }}
                />
              </div>

              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Valores do Z1:
              </h2>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DESCRIÇÃO</th>
                        <th>VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ângulo Pressão:</td>
                        <td>{angle}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">da</span>1(Diametro
                          externo):
                        </td>
                        <td>{valuesAPI.data.diameter1.toFixed(2)} MM</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">d</span>1(Diametro
                          primitivo):
                        </td>
                        <td>
                          {valuesAPI.data.primitive_diameter1.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">δ</span>1(Ângulo
                          primitivo):
                        </td>
                        <td>{valuesAPI.data.primitive_angle1.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td> Módulo:</td>
                        <td>{valuesAPI.data.module1.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ψ</span>1(Ângulo do pé
                          do dente):
                        </td>
                        <td>{valuesAPI.data.foot_angle1.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ω</span>1(Ângulo externo
                          do torno:)
                        </td>
                        <td>{valuesAPI.data.external_angle1.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">Ad</span>1(Ângulo do
                          divisor):
                        </td>
                        <td>{valuesAPI.data.divisor_angle1.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <span className="normal-case">γ</span>1(Ângulo da
                          cabeça do dente):
                        </td>
                        <td>{valuesAPI.data.head_angle1.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <span className="normal-case">h</span>1(Altura do
                          dente):
                        </td>
                        <td>{valuesAPI.data.teeth_size1.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h2 className="mt-6 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                  Relação de discos encontrados do Z1:
                </h2>
              </div>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DISCO:</th>
                        <th>ROTAÇÕES:</th>
                        <th>FUROS A PERCORRER:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {valuesAPI.data?.disc1 &&
                        valuesAPI.data.disc1.map((e, index) => (
                          <tr key={index}>
                            <td>{e.disc}</td>
                            <td>{e.rotations}</td>
                            <td>{e.hole}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <h2 className="mt-5 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Valores do Z2:
              </h2>

              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DESCRIÇÃO</th>
                        <th>VALOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ângulo Pressão:</td>
                        <td>{angle}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">da</span>2(Diametro
                          externo):
                        </td>
                        <td>{valuesAPI.data.diameter2.toFixed(2)} MM</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">d</span>2(Diametro
                          primitivo):
                        </td>
                        <td>
                          {valuesAPI.data.primitive_diameter2.toFixed(2)} MM
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">δ</span>2(Ângulo
                          primitivo):
                        </td>
                        <td>{valuesAPI.data.primitive_angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td> Módulo:</td>
                        <td>{valuesAPI.data.module2.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">ψ</span>2( Ângulo do pé
                          do dente):
                        </td>
                        <td>{valuesAPI.data.foot_angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <span className="normal-case">ω</span>2(Ângulo externo
                          do torno):
                        </td>
                        <td>{valuesAPI.data.external_angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="normal-case">Ad</span>2(Ângulo do
                          divisor):
                        </td>
                        <td>{valuesAPI.data.divisor_angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <span className="normal-case">γ</span>2(Ângulo da
                          cabeça do dente):
                        </td>
                        <td>{valuesAPI.data.head_angle2.toFixed(2)}°</td>
                      </tr>
                      <tr>
                        <td>
                          {' '}
                          <span className="normal-case">h</span>2(Altura do
                          dente):
                        </td>
                        <td>{valuesAPI.data.teeth_size2.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h2 className="mt-6 text-center text-2xl font-bold leading-4 tracking-tight text-gray-900">
                  Relação de discos encontrados do Z2:
                </h2>
              </div>
              <div className="values-api_response">
                <div className="container_engrenagens table-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <th>DISCO:</th>
                        <th>ROTAÇÕES:</th>
                        <th>FUROS A PERCORRER:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {valuesAPI.data?.disc2 &&
                        valuesAPI.data.disc2.map((e, index) => (
                          <tr key={index}>
                            <td>{e.disc}</td>
                            <td>{e.rotations}</td>
                            <td>{e.hole}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center items-center gap-4">
              <button
                onClick={() => setMostraModal(false)}
                className="block w-40 bg-red-600 py-2 rounded-2xl text-white font-semibold shadow-md hover:bg-red-400 transition duration-300"
              >
                VOLTAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseEngConicaAPI;
