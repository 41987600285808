import React, { useState } from 'react';
import './PayloadDaD.css';
import api from '../../axios/config';
import ResponseDaDAPI from '../ResponseDaDAPI/ResponseDaDAPI';
import Loading from '../Loading/Loading';
import Header from '../Header/Header';
import Input from '../Input/Input';

const PayloadDaD = () => {
  const tokenAuthenticated = sessionStorage.getItem('token');
  const [numberCogs, setNumberCogs] = useState(4);
  const [pitchOrHelixAngle, setPitchOrHelixAngle] = useState('');
  const [diameter, setDiameter] = useState('');
  const [numberToothGrinding, setNumberToothGrinding] = useState('');
  const [fuscoAdvance, setFuscoAdvance] = useState('');
  const [fixedReduction, setFixedReduction] = useState('');
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [gearType, setGearType] = useState('Direita');
  const [angleOrStep, setAngleOrStep] = useState('Ângulo');
  const [mostraModal, setMostraModal] = useState(false);
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);

  const postApiCalculatedDaD = async () => {
    const payload = {
      teeth: Number(numberToothGrinding),
      crown_teeth: Number(fuscoAdvance),
      spindle_pitch: Number(fixedReduction),
      num_engines: Number(numberCogs),
      diameter: Number(diameter),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
      gear_type: gearType,
    };
    if (angleOrStep === 'Ângulo') {
      payload.angle = gearType === 'Dente Reto' ? 0 : Number(pitchOrHelixAngle);
    } else {
      payload.step = gearType === 'Dente Reto' ? 0 : Number(pitchOrHelixAngle);
    }

    setLoading(true);
    setValuesAPI([]);
    await api
      .post('engine/calc_dad', payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error('ERRO ->', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 className="titulo">
        Cálculo:{' '}
        <strong>
          ENGRENAGEM PARALELA RETA OU HELICOIDAL NA FRESADORA UNIVERSAL
        </strong>
      </h1>
      <div className="max-w-3xl mx-auto p-6 bg-white shadow-xl mt-3 rounded-lg">
        <div className="mb-4 p-1 bg-gray-100 rounded-md shadow text-center">
          <label className="text-lg font-medium text-gray-700">
            Número de engrenagens:
          </label>
          <div className="flex justify-center gap-4 mt-2">
            {[2, 4].map((value) => (
              <label
                key={value}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  value={value}
                  checked={numberCogs === value}
                  onChange={(event) =>
                    setNumberCogs(parseInt(event.target.value))
                  }
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all ${
                    numberCogs === value
                      ? 'border-blue-600 bg-blue-600'
                      : 'border-gray-400 bg-white'
                  }`}
                >
                  {numberCogs === value && (
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  )}
                </div>
                <span className="text-lg font-bold text-gray-800">{value}</span>
              </label>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Sentido de Hélice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Direita">Direita</option>
              <option value="Esquerda">Esquerda</option>
              <option value="Dente Reto">Dente Reto</option>
            </select>
          </div>
          {gearType !== 'Dente Reto' && (
            <div>
              <label className="block text-gray-700 text-sm font-bold">
                Ângulo / Passo da Hélice (Eng):*
              </label>
              <select
                value={angleOrStep}
                onChange={(e) => setAngleOrStep(e.target.value)}
                className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
              >
                <option value="Ângulo">Ângulo</option>
                <option value="Passo">Passo</option>
              </select>
              <input
                type="number"
                value={pitchOrHelixAngle}
                placeholder={`${angleOrStep} de Hélice (Eng)`}
                onChange={(e) => setPitchOrHelixAngle(e.target.value)}
                onWheel={(e) => e.target.blur()}
                className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
              />
            </div>
          )}
          {[
            {
              label: 'Diâmetro da Engrenagem:*',
              value: diameter,
              setter: setDiameter,
              placeholder: 'Diâmetro Externo',
            },
            {
              label: 'Número de dentes (Eng.):*',
              value: numberToothGrinding,
              setter: setNumberToothGrinding,
              placeholder: 'Engrenagem (Z)',
            },
            {
              label: 'Passo do Fuso (Fres.):*',
              value: fixedReduction,
              setter: setFixedReduction,
              placeholder: 'Passo Fuso Fresadora',
            },
            {
              label: 'Número de dentes (Cor. Div):*',
              value: fuscoAdvance,
              setter: setFuscoAdvance,
              placeholder: '(Z) Coroa Divisor',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>
        <div className="mt-8 mb-2">
          <strong>CONFIGURAÇÕES:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          {[
            {
              label: 'Número de Dentes mínimos:*',
              value: minTeeth,
              setter: setMinTeeth,
              placeholder: 'Número de Dentes mínimos',
            },
            {
              label: 'Número de Dentes máximos:*',
              value: maxTeeth,
              setter: setMaxTeeth,
              placeholder: 'Número de Dentes máximos',
            },
            {
              label: 'Número de exemplos:*',
              value: numberSample,
              setter: setNumberSample,
              placeholder: 'Número de exemplos',
            },
            {
              label: 'Precisão:*',
              value: precision,
              setter: setPrecision,
              placeholder: 'Precisão',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>

        <div className="my-6">
          <button
            className={`w-full py-3 rounded-lg font-semibold text-white transition ${
              (gearType !== 'Dente Reto' && pitchOrHelixAngle === '') ||
              diameter === '' ||
              numberToothGrinding === '' ||
              fuscoAdvance === '' ||
              fixedReduction === '' ||
              minTeeth === '' ||
              maxTeeth === '' ||
              numberSample === '' ||
              precision === ''
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            onClick={() => {
              postApiCalculatedDaD();
              setMostraModal(true);
            }}
            disabled={
              (gearType !== 'Dente Reto' && pitchOrHelixAngle === '') ||
              diameter === '' ||
              numberToothGrinding === '' ||
              fuscoAdvance === '' ||
              fixedReduction === '' ||
              minTeeth === '' ||
              maxTeeth === '' ||
              numberSample === '' ||
              precision === ''
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseDaDAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              diameter={diameter}
              pitchOrHelixAngle={pitchOrHelixAngle}
              numberToothGrinding={numberToothGrinding}
              fuscoAdvance={fuscoAdvance}
              fixedReduction={fixedReduction}
              numberCogs={numberCogs}
              gearType={gearType}
              angleOrStep={angleOrStep}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadDaD;
