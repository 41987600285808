import React, { useState } from 'react';
import Header from '../Header/Header';
import Input from '../Input/Input';
import Loading from '../Loading/Loading';
import api from '../../axios/config';
import ResponseEngHipoideAPI from '../ResponseEngHipoideAPI/ResponseEngHipoideAPI';

const PayloadEngHipoide = () => {
  const tokenAuthenticated = sessionStorage.getItem('token');
  const [axisAngle, setAxisAngle] = useState(90);
  const [numberCogs, setNumberCogs] = useState(4);
  const [teethz1, setTeethz1] = useState('');
  const [teethz2, setTeethz2] = useState('');
  const [crownTeeth1, setCrownTeeth1] = useState('');
  const [valuesAPI, setValuesAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostraModal, setMostraModal] = useState(false);
  const [gearType, setGearType] = useState('Direita');
  const [pitchOrHelixAngle, setPitchOrHelixAngle] = useState('');
  const [angleOrStep, setAngleOrStep] = useState('Ângulo');
  const [fixedReduction, setFixedReduction] = useState('');
  const [minTeeth, setMinTeeth] = useState(20);
  const [maxTeeth, setMaxTeeth] = useState(100);
  const [module, setModule] = useState('');
  const [moduleOrdiameter, setModuleOrdiameter] = useState('Módulo');
  const [diameterExternal1, setDiameterExternal1] = useState('');
  const [diameterExternal2, setDiameterExternal2] = useState('');
  const [numberSample, setNumberSample] = useState(100);
  const [precision, setPrecision] = useState(5);
  const [pressureAngle, setPressureAngle] = useState(15);

  const postApiCalculatedEngConica = async () => {
    const payload = {
      pressure_angle: Number(pressureAngle),
      crown_teeth: Number(crownTeeth1),
      spindle_pitch: Number(fixedReduction),
      gear_type: gearType,
      ...(moduleOrdiameter === 'Módulo'
        ? { module: Number(module) }
        : {
            diameter: Number(diameterExternal1),
            diameter2: Number(diameterExternal2),
          }),
      num_engines: Number(numberCogs),
      teeth: Number(teethz1),
      teeth2: Number(teethz2),
      axis_angle: Number(axisAngle),
      min_cog: Number(minTeeth),
      max_cog: Number(maxTeeth),
      precision: Number(precision),
      samples: Number(numberSample),
    };
    if (angleOrStep === 'Ângulo') {
      payload.angle = gearType === 'Dente Reto' ? 0 : Number(pitchOrHelixAngle);
    } else {
      payload.step = gearType === 'Dente Reto' ? 0 : Number(pitchOrHelixAngle);
    }
    setLoading(true);
    setValuesAPI([]);
    await api
      .post('engine/calc_renania_hipoide', payload, {
        headers: {
          token: tokenAuthenticated,
        },
      })
      .then((response) => {
        const data = response.data;
        setValuesAPI(data);
      })
      .catch((error) => {
        console.error('ERRO ->', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <h1 className="titulo">
        Cálculo:{' '}
        <strong>ENGRENAGEM CONICA ESPIRAL NA FRESADORA UNIVERSAL</strong>
      </h1>
      <div className="max-w-3xl mx-auto p-6 bg-white shadow-xl mt-3 rounded-lg">
        <div className="mb-4 p-1 bg-gray-100 rounded-md shadow text-center">
          <label className="text-lg font-medium text-gray-700">
            Número de engrenagens:
          </label>
          <div className="flex justify-center gap-4 mt-2">
            {[2, 4].map((value) => (
              <label
                key={value}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  value={value}
                  checked={numberCogs === value}
                  onChange={(event) =>
                    setNumberCogs(parseInt(event.target.value))
                  }
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all ${
                    numberCogs === value
                      ? 'border-blue-600 bg-blue-600'
                      : 'border-gray-400 bg-white'
                  }`}
                >
                  {numberCogs === value && (
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  )}
                </div>
                <span className="text-lg font-bold text-gray-800">{value}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="mb-4 p-1 bg-gray-100 rounded-md shadow text-center">
          <label className="text-lg font-medium text-gray-700">
            Ângulo Pressão:
          </label>
          <div className="flex justify-center gap-4 mt-2">
            {[15, 20].map((value) => (
              <label
                key={value}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  value={value}
                  checked={pressureAngle === value}
                  onChange={(event) =>
                    setPressureAngle(parseInt(event.target.value))
                  }
                  className="hidden"
                />
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all ${
                    pressureAngle === value
                      ? 'border-blue-600 bg-blue-600'
                      : 'border-gray-400 bg-white'
                  }`}
                >
                  {pressureAngle === value && (
                    <div className="w-3 h-3 rounded-full bg-white"></div>
                  )}
                </div>
                <span className="text-lg font-bold text-gray-800">{value}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4"></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Sentido de Hélice da Engrenagem:*
            </label>
            <select
              value={gearType}
              onChange={(e) => setGearType(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Direita">Direita</option>
              <option value="Esquerda">Esquerda</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Ângulo / Passo da Hélice (Eng):*
            </label>
            <select
              value={angleOrStep}
              onChange={(e) => setAngleOrStep(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Ângulo">Ângulo</option>
              <option value="Passo">Passo</option>
            </select>
            <input
              type="number"
              value={pitchOrHelixAngle}
              placeholder={`${angleOrStep} de Hélice (Eng)`}
              onChange={(e) => setPitchOrHelixAngle(e.target.value)}
              onWheel={(e) => e.target.blur()}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold">
              Módulo / Diametro:*
            </label>
            <select
              value={moduleOrdiameter}
              onChange={(e) => setModuleOrdiameter(e.target.value)}
              className="w-full h-10 mt-1 px-3 border rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-300"
            >
              <option value="Módulo">Módulo</option>
              <option value="Diametro">Diametro</option>
            </select>
          </div>
          {[
            {
              condition: moduleOrdiameter === 'Módulo',
              label: 'Módulo :*',
              value: module,
              setter: setModule,
              placeholder: 'Módulo',
            },
            {
              condition: moduleOrdiameter === 'Diametro',
              label: 'Diametro Externo Z1:*',
              value: diameterExternal1,
              setter: setDiameterExternal1,
              placeholder: 'Diametro Externo Z1',
            },
            {
              condition: moduleOrdiameter === 'Diametro',
              label: 'Diametro Externo Z2:*',
              value: diameterExternal2,
              setter: setDiameterExternal2,
              placeholder: 'Diametro Externo Z2',
            },
            {
              condition: true,
              label: 'Número de dentes(Cor. Div):*',
              value: crownTeeth1,
              setter: setCrownTeeth1,
              placeholder: '(Z) Coroa Divisor',
            },
            {
              condition: true,
              label: 'Dentes do Z1:*',
              value: teethz1,
              setter: setTeethz1,
              placeholder: 'Z1',
            },
            {
              condition: true,
              label: 'Dentes do Z2:*',
              value: teethz2,
              setter: setTeethz2,
              placeholder: 'Z2',
            },
            {
              condition: true,
              label: 'Ângulo do eixo:*',
              value: axisAngle,
              setter: setAxisAngle,
              placeholder: 'Ângulo do eixo',
            },
            {
              condition: true,
              label: 'Passo do Fuso(Fres.):*',
              value: fixedReduction,
              setter: setFixedReduction,
              placeholder: 'Passo Fuso Fresadora',
            },
          ]
            .filter(({ condition }) => condition)
            .map(({ label, value, setter, placeholder }) => (
              <Input
                label={label}
                value={value}
                placeholder={placeholder}
                onChange={(e) => setter(e.target.value)}
              />
            ))}
        </div>
        <div className="mt-8 mb-2">
          <strong>CONFIGURAÇÕES:</strong>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          {[
            {
              label: 'Número de Dentes mínimos:*',
              value: minTeeth,
              setter: setMinTeeth,
              placeholder: 'Número de Dentes mínimos',
            },
            {
              label: 'Número de Dentes máximos:*',
              value: maxTeeth,
              setter: setMaxTeeth,
              placeholder: 'Número de Dentes máximos',
            },
            {
              label: 'Número de exemplos:*',
              value: numberSample,
              setter: setNumberSample,
              placeholder: 'Número de exemplos',
            },
            {
              label: 'Precisão:*',
              value: precision,
              setter: setPrecision,
              placeholder: 'Precisão',
            },
          ].map(({ label, value, setter, placeholder }) => (
            <Input
              label={label}
              value={value}
              placeholder={placeholder}
              onChange={(e) => setter(e.target.value)}
            />
          ))}
        </div>

        <div className="my-6">
          <button
            className={`w-full py-3 rounded-lg font-semibold text-white transition ${
              pitchOrHelixAngle === '' ||
              teethz1 === '' ||
              teethz2 === '' ||
              axisAngle === '' ||
              crownTeeth1 === '' ||
              fixedReduction === '' ||
              (moduleOrdiameter === 'Diametro'
                ? diameterExternal1 === '' || diameterExternal2 === ''
                : module === '')
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            onClick={() => {
              postApiCalculatedEngConica();
              setMostraModal(true);
            }}
            disabled={
              pitchOrHelixAngle === '' ||
              teethz1 === '' ||
              teethz2 === '' ||
              axisAngle === '' ||
              crownTeeth1 === '' ||
              fixedReduction === '' ||
              (moduleOrdiameter === 'Diametro'
                ? diameterExternal1 === '' || diameterExternal2 === ''
                : module === '')
            }
          >
            Calcular
          </button>

          {loading && <Loading />}
          {!loading && mostraModal && (
            <ResponseEngHipoideAPI
              valuesAPI={valuesAPI}
              setMostraModal={setMostraModal}
              pitchOrHelixAngle={pitchOrHelixAngle}
              crownTeeth1={crownTeeth1}
              fixedReduction={fixedReduction}
              numberCogs={numberCogs}
              gearType={gearType}
              angleOrStep={angleOrStep}
              pressureAngle={pressureAngle}
              z1={teethz1}
              z2={teethz2}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PayloadEngHipoide;
